<template lang="pug">
	#app
		// nav#Streamers
		// ul
		// template(v-for="caster in orderByAlpha")
		// li
		// a(v-bind:href="'//twitch.tv/'+caster.name" target="_blank") {{ caster.displayName }}
		header#Header
			h1
				a(href='http://wouldyoukindly.com')
					img.wordmark(src='//wouldyoukindly.com/2022_wyk_resources/logos/wyk-logo-wordmark.png' alt='WYK : Would You Kindly')
		main#Main
			.wrap
				#PrimaryInfo
					.information
						h2
							span &ldquo;Where most see chaos...
							br
							span.underline ...we see creative potential&rdquo;
							// br
							// span(style="text-align:center; font-size : 0.6em") - Ancient Canadian Proverb
						p
							abbr(title='Would You Kindly') WYK
							|  is Twitch&rsquo;s favorite dumpster-fire of a team, dedicated to bringing you the best variety content that you can&rsquo;t wait to engage with.
						p
							| Our roster of partnered streamers house an incredibly diverse selection of content, but dispite our differences, there is one thing that our team will always agree on: community is our priority.
						p
							| Both online and 
							abbr(title='In Real Life') IRL
							| , we continue to foster an incredibly tight-knit community that spans across all our teammates channels. From our con meetups to our monthly events like
							strong A Day With Would You Kindly
							|  ( 
							abbr(title="A Day With Would You Kindly") ADWWYK
							|  ) 
							| , and we aim to create content for our viewers that is engaging, exciting, and often unexpected.
						p Would You Kindly Let Us Entertain You?
					img.wykImage(src='//wouldyoukindly.com/2022_wyk_resources/caster_images/rom-sloth.jpg' title='' alt='Image of TheRomShow and a Sloth when WYK invaded the San Diego Zoo during TwitchCon 2019')
					span.clearfix
			section#Casters
				ul
					template(v-for='caster in orderByAlpha')
						li(v-on:click='goToUrl(caster)')
							.information
								.name {{ caster.displayName }}
							.layer.foreground(:style='[cardCasterTransform(caster),cardCasterImage(caster)]')
							.layer.background(:style='[cardBgTransform(caster),cardBgImage(caster)]')
		footer#Footer
			#MainFooter
				.wrap
					ul.footer_panel_container
						li.footer_panel
							h3 Follow Us
							ul
								li
									a(href='http://twitch.tv/team/wyktv' title='WYK on Twitch') Twitch
								li
									a(href='https://www.youtube.com/channel/UCDfmQq5QEUeg7fjfRADcsvw' title='WYK on YouTube') YouTube
								li
									a(href='https://twitter.com/wouldyoukindly' title='WYK on Twitter') Twitter
								li
									a(href='https://www.instagram.com/wyktv/' title='WYK on Instagram') Instagram
						li.footer_panel
							h3 Contact Us
							ul
								li.heading Public Relations
								li.name Firecrow
								li.email
									a(href='mailto:pr@wouldyoukindly.com' title='Contact Email for Public Relations') pr@wouldyoukindly.com
								li.twitter
									a(href='http://twitter.com/firecrowtv' title='Twitter for Public Relations') @FirecrowTV
			#SubFooter
				p Copyright &copy; 2022 WouldYouKindlyTV. All rights reserved.
				p Website Design by 'Lack Of Content' Rob
</template>

<script>
	import "@/assets/Global.css"
	import CasterCard from '@/components/CasterCard'

	const _sort_compareByAlpha = (a,b)=>{
		return a.alphaName.localeCompare(b.alphaName);
	}

	export default	{
		name		: "App",
		data		: ()=>({
			casters : [
				{
					name : 'domesticdan',
					displayName : 'Domestic Dan',
					shortDisplayName : 'Domestic Dan',
					alphaName : 'Domestic Dan',
					// tagline : `He Has a Mortgage`,
				},
				{
					name : 'danicarockwood',
					displayName : 'Danica Rockwood',
					shortDisplayName : 'Danica Rockwood',
					alphaName : 'Danica Rockwood',
					// tagline : ``,
				},
				{
					name : 'spoonee',
					displayName : 'Spoonee',
					shortDisplayName : 'Spoonee',
					alphaName : 'Spoonee',
					// tagline : ``,
				},
				{
					name : 'lackofcontent',
					displayName : 'Lack Of Content',
					shortDisplayName : 'LackOfContent',
					alphaName : 'Lack Of Content',
					// tagline : 'Never Run Out of Content!',
				},
				{
					name : 'ruutv',
					displayName : 'Ruu TV',
					shortDisplayName : 'Ruu TV',
					alphaName : 'Ruu TV',
					// tagline : 'Variety!',
				},
				{
					name : 'firecrow',
					displayName : 'Firecrow',
					shortDisplayName : 'Firecrow',
					alphaName : 'Firecrow',
					// tagline : 'Professional Dad',
				},
				{
					name : 'overboredgaming',
					displayName : 'Overbored Gaming',
					shortDisplayName : 'OBG',
					alphaName : 'Overbored Gaming',
					// tagline : 'Double Trouble',
				},
				{
					name : 'katiepetersplays',
					displayName : 'KatiePetersPlays',
					shortDisplayName : 'KPP',
					alphaName : 'Katie Peters Plays',
					// tagline : 'Tag, Your',
				},
				{
					name : 'imkatt',
					displayName : 'ImKatt',
					shortDisplayName : 'ImKatt',
					alphaName : 'ImKatt',
					// tagline : 'Double Trouble',
				},
/*
				{
					name : 'farringtonempire',
					displayName : 'Farrington Empire',
					shortDisplayName : 'Farrington',
					alphaName : 'Farrington Empire',
					tagline : 'Like A Sir!',
				},
*/
				{
					name : 'theromshow',
					displayName : 'TheRomShow',
					shortDisplayName : 'Rom',
					alphaName : 'RomShow',
					// tagline : 'Secretly Canadian',
				},
				{
					name : 'goobers515',
					displayName : 'Goobers515',
					shortDisplayName : 'Goobers',
					alphaName : 'Goobers515',
					// tagline : 'He seems so surprised',
				}
			]
		}),
		components	: { CasterCard },
		computed	: {
			orderByAlpha () {
				return [...this.casters].sort(_sort_compareByAlpha);
			}
		},
		methods : {
			cardCasterTransform({name}){
				return;
			},
			cardCasterImage({name}){
				return {
					backgroundImage : `url(//wouldyoukindly.com/2022_wyk_resources/caster_images/${name}.png)`
				}
			},
			cardBgTransform({name}){
				return;
			},
			cardBgImage({name}){
				return {
					backgroundImage : `url(//wouldyoukindly.com/2022_wyk_resources/caster_images/${name}-backdrop.png)`
				}
			},
			goToUrl({name}){
				window.open(`//twitch.tv/${name}`, "_blank");
			},
		},

	}
</script>