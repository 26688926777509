<template lang="pug">
	.CardWrap(@mousemove="handleMouseMove" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" ref="card")
		.Card(:style="cardStyle" @click="goToUrl")
			div(v-if="cardLive").card-live
			.card-caster(:class="{ attachTop : dataAttachTop}" :style="[cardCasterTransform,cardCasterImage]")
			.card-bg(:style="[cardBgTransform,cardBgImage]")
			.card-upper-layer
			.card-info-wrap
				.card-info
					slot(name="header")
					slot(name="content")
</template>

<script>
	export default	{
		name		: 'CasterCard',
		
		props		: ['dataCaster','dataLive','dataAttachTop'],
		
		data		: ()=>({
			width			: 0,
			height			: 0,
			mouseX			: 0,
			mouseY			: 0,
			mouseLeaveDelay : null
		}),

		computed	: {
			mousePX				() { return this.mouseX / this.width },
			mousePY				() { return this.mouseY / this.height },
			cardStyle			() {
				let rX = this.mousePX * 15,
					rY = this.mousePY * -15;
				return {
					transform : `rotateY(${rX}deg) rotateX(${rY}deg)`
				}
			},
			cardBgTransform		() {
				let tX = this.mousePX * -120,
					tY = this.mousePY * -120;
				return {
					transform : `translateX(${tX}px) translateY(${tY}px)`
				}
			},
			cardBgImage			() {
				return {
					backgroundImage : `url('//wouldyoukindly.com/2022_wyk_resources/caster_images/${this.dataCaster}-backdrop.png')`
				}
			},
			cardCasterTransform	() {
				let tX = this.mousePX * -40,
					tY = this.mousePY * -40;
				return {
					transform : `translateX(${tX}px) translateY(${tY}px)`
				}
			},
			cardCasterImage		() {
				return {
					backgroundImage : `url('//wouldyoukindly.com/2022_wyk_resources/caster_images/${this.dataCaster}.png')`
				}
			},
			cardLive			() {
				if (this.dataLive) return true
				return false
			},
		},

		methods		: {
			goToUrl				() {
				window.open(`//twitch.tv/${this.dataCaster}`, "_blank")
			},
			handleMouseMove		(event) {
				const { offsetWidth, offsetHeight } = this.$refs.card
				if (!window.matchMedia("(min-width: 1040px)").matches) return
				this.mouseX = e.pageX - offsetLeft - this.width/2
				this.mouseY = e.pageY - offsetTop - this.height/2
			},
			handleMouseEnter	() {
				clearTimeout(this.mouseLeaveDelay)
			},
			handleMouseLeave	() {
				this.mouseLeaveDelay = setTimeout(()=>{
					this.mouseX = 0
					this.mouseY = 0
				},1000)
			}
		},



		mounted(){
			const { offsetWidth, offsetHeight } = this.$refs.card
			this.width	= offsetWidth
			this.height	= offsetHeight
		}
	}
</script>


<style lang="stylus">
</style>


<style lang="stylus" scoped>
</style>